<template>
  <v-container fluid>
    <div class="pt-4 pb-6">
      <div
        class="text-h4 ma-6 mt-2 mb-2 text-center font-weight-medium header-font"
      >
        Let the magic slide into the DMs.
      </div>
      <InstagramFeed class="mt-16" />
      <div class="mt-8 text-center">
        <v-btn
          variant="outlined"
          class="rounded-0 text-overline text-uppercase"
          @click="navigateToInstagram"
          >Follow us on Instagram</v-btn
        >
      </div>
    </div>
  </v-container>
</template>

<script>
import InstagramFeed from "@/components/InstagramFeed.vue";
export default {
  name: "InteriorView",
  components: {
    InstagramFeed,
  },
  setup() {
    const navigateToInstagram = () => {
      window.open("https://www.instagram.com/spellbound_india", "_blank");
    };

    return {
      navigateToInstagram,
    };
  },
};
</script>

<style lang="scss" scoped></style>
