module.exports = {
  links: [
    {
      id: 1,
      label: "Interior Narratives’",
      menuItems: [{ id: 1, label: "All", url: "/interior" }],
      defaultUrl: "/interior",
    },
    {
      id: 2,
      label: "Chronicles",
      menuItems: [
        { id: 21, label: "All", url: "/chronicles" },
        {
          id: 22,
          label: "Interior Design Trends",
          url: "/chronicles/interior-design-trends",
        },
        {
          id: 23,
          label: "Life at Spellbound",
          url: "/chronicles/life-at-spellbound",
        },
        {
          id: 24,
          label: "Signature Projects",
          url: "/chronicles/signature-projects",
        },
        { id: 25, label: "Sensory Design", url: "/chronicles/sensory-design" },
      ],
      defaultUrl: "/chronicles",
    },
    {
      id: 3,
      label: "Our Story",
      menuItems: [
        { id: 31, label: "Spellbound", url: "/our-story/spellbound" },
        {
          id: 32,
          label: "Our Design Culture",
          url: "/our-story/design-culture",
        },
      ],
      defaultUrl: "/our-story/spellbound",
    },
    {
      id: 4,
      label: "About",
      menuItems: [{ id: 41, label: "Contact Us", url: "/contact" }],
      defaultUrl: "/contact",
    },
  ],
  projectBlocks: [
    {
      id: 1,
      component: "FullImageBlock",
      subBlocks: [
        {
          id: 11,
          title: "Interiors",
          subtitle: "",
          body: "We believe that every space has a story to tell.<br />Our interior design approach is inspired by context and enchanted with fine craftsmanship. The beauty of tones and textures meets the magic of tales to craft tactile spaces that offer a sensory experience.",
          bodySubtitle: "",
          url: "",
          image:
            "https://lh3.googleusercontent.com/d/1padtLRouIN6lLQlg1BSUXKQvkqkNr-lZ=w1000",
        },
      ],
    },
    {
      id: 2,
      component: "TextBlock",
      subBlocks: [
        {
          id: 21,
          title: "",
          subtitle: "",
          body: "Design is a powerful genre that has the power to touch lives, ​make memories, and build togetherness. ​Our work is purposeful, adding beauty ​and joy to your world.",
          bodySubtitle: "",
          url: "",
          image: "",
        },
      ],
    },
    {
      id: 3,
      component: "LeftImageTextBlock",
      subBlocks: [
        {
          id: 31,
          title: "The Pavilion",
          subtitle: "A home to retire into for a cricket historian",
          body: "We’re all about working collaboratively with our clients—engaging, listening, questioning—until, together, we exceed expectations and define design excellence.",
          bodySubtitle: "",
          url: "/interior/the-pavilion",
          image:
            "https://lh3.googleusercontent.com/d/1l3aHsUmz-iT7x27p8y-VTAlWLF7wiFtw=w1000",
        },
      ],
    },
    {
      id: 4,
      component: "TopRightImageTextBlock",
      subBlocks: [
        {
          id: 41,
          title: "Ishavasya, Where the Lord resides",
          subtitle: "‘Reverence’ as a space",
          body: "At the heart of our philosophy is the belief that spaces are personal extensions of the individual.",
          bodySubtitle: "",
          url: "/interior/ishavasya",
          image:
            "https://lh3.googleusercontent.com/d/1padtLRouIN6lLQlg1BSUXKQvkqkNr-lZ=w1000",
        },
      ],
    },
    {
      id: 5,
      component: "LeftImageTextBlock",
      subBlocks: [
        {
          id: 51,
          title: "Beach House, ECR Chennai",
          subtitle:
            "In here, ‘To have done less, is indeed to have done more’ …",
          body: "Design that supports the human body, mind, and spirit.​",
          bodySubtitle: "",
          url: "/interior/beach-house",
          image:
            "https://lh3.googleusercontent.com/d/1zng5-SFU_VrVkCTlkKspkFjDwBckW3aZ=w1000",
        },
      ],
    },
    {
      id: 6,
      component: "RightImageTextBlock",
      subBlocks: [
        {
          id: 61,
          title: "Essencia, For the Senses.",
          subtitle:
            "Spa.Salon.Stretch Studio<br />‘Sheer indulgence in bliss’ this",
          body: "We put people at the center, crafting spaces that resonate deeply with who they truly are.​​",
          bodySubtitle: "​​​",
          url: "/interior/essencia",
          image:
            "https://lh3.googleusercontent.com/d/1HwwWvxFX6Qc2mpnaI_19zWBTSfiuYYi5=w1000",
        },
      ],
    },
    {
      id: 7,
      component: "LeftFullImageBlock",
      subBlocks: [
        {
          id: 71,
          title: "Our ‘Ode to Timber’ at N17",
          subtitle: "",
          body: "Every project is a narrative woven with care, turning each environment into a poetic experience.",
          bodySubtitle: "",
          url: "/interior/ode-to-timber",
          image:
            "https://lh3.googleusercontent.com/d/1zP0zxxrDwKg6eXfEyFyyc008htLl7hK7=w1000",
        },
      ],
    },
    {
      id: 8,
      component: "RightImageTextBlock",
      subBlocks: [
        {
          id: 81,
          title: "Kimera Wellness",
          subtitle: "Where you train your soul to ‘aspire’",
          body: "Our design process is fueled by a relentless curiosity that compels us to explore deeply and innovate constantly.​",
          bodySubtitle: "​",
          url: "/interior/kimera-wellness",
          image:
            "https://lh3.googleusercontent.com/d/1mCyJWWhHkdC4GBaDsXDlr9iOKe7XlqGJ=w1000",
        },
      ],
    },
    {
      id: 9,
      component: "TopLeftBottomRightImageBlock",
      subBlocks: [
        {
          id: 91,
          title: "The New York Loft",
          subtitle: "Moonlight in NY city, the charm",
          body: "Design is a powerful genre that has the power to touch lives, ​make memories, and build togetherness. ​Our work is purposeful, adding beauty ​and joy to your world.",
          bodySubtitle: "",
          url: "/interior/new-york-loft",
          image:
            "https://lh3.googleusercontent.com/d/1oQE93ux2ToLxBWwWpSZBSiH1o68SY-8e=w1000",
        },
        {
          id: 92,
          title: "For the ‘Joy of Dressing’- A Closet",
          subtitle: "It’s a charmed world in there!",
          body: "With you—our clients, communities, partners, and peers—we aim to create a more beautiful, conscious, equitable world. Together, we design for a holistic life.",
          bodySubtitle: "",
          url: "/interior/a-closet",
          image:
            "https://lh3.googleusercontent.com/d/1SHZCTFCJLa8hvD4dhsgKsbD_-_qINvMS=w1000",
        },
      ],
    },
  ],
  projects: {
    "the-pavilion": {
      mainImage:
        "https://lh3.googleusercontent.com/d/1zpZS5UdIWNIPQhLO5axJ_Uf3Ri6Q4kFx=w1000",
      title: "The Pavilion, a Cricket Historian’s Second Home",
      caption: "The joy of living off the field.",
      subtitle: ["Luxury Residence", "Bangalore"],
      info: [
        {
          main: "AREA (Site)",
          subtitle: "2600 sq. ft.",
        },
        {
          main: "Status",
          subtitle: "Completed",
        },
      ],
      sections: [
        {
          id: 2,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/1_qRU8a9vNkrTtKmbrb-cT6a3ycChTzEy=w1000",
            "https://lh3.googleusercontent.com/d/1l3aHsUmz-iT7x27p8y-VTAlWLF7wiFtw=w1000",
          ],
          body: "Cricket takes cricketers across the world for tests, tournaments, etc. At the end of it all, a cricketer returns to his comfort—to a home where the family comes together. The Pavilion is that comfort sanctuary for a cricket historian. This 2600 sq. ft. home is living proof that a cricketer’s creativity isn’t bound to the crease alone.",
        },
        {
          id: 3,
          imageTop: true,
          images: [
            "https://lh3.googleusercontent.com/d/14BNvRRBhLV2FoCvtgmLVzGZTW91j1dkY=w1000",
            "https://lh3.googleusercontent.com/d/1bLsRkZiYxfCycGqYl8fb_GV4AiEZ59bi=w1000",
          ],
          body: "The Pavilion is built on the philosophy of crafting spaces that capture a personal journey. The wife– a devoted teacher for special needs children and a practicing Buddhist- brings her appreciation for bright tones from Bali aesthetics to the home.<br /><br />To shape up the design story, we began by requesting three of her favorite sarees. From there, we borrowed an eclectic mood board with tones, patterns, and intricacy in design. Each saree told us a story of emotional resonance with the shades. And that’s how a personalized and warm interior design meditates on the true meaning of ‘home.’",
        },
        {
          id: 4,
          imageTop: true,
          images: [
            "https://lh3.googleusercontent.com/d/1HlOvjDCKw53iaVvyeVBqmAEnSMqghuGB=w1000",
            "https://lh3.googleusercontent.com/d/1E9Iuc-vdlMUf7gb_qNDrXnksyh1naSop=w1000",
          ],
          body: "",
        },
        {
          id: 5,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/1kzm717YJq3dZNe2o1NM3RT7vk39E2Zbe=w1000",
            "https://lh3.googleusercontent.com/d/1n90iXgaGZtamLXZYgUhz9XNnHTf8RAr4=w1000",
          ],
          body: "This home celebrates the couple's individual stories through a touch of tropical elements, bright-coloured textiles, and natural wood. A carefully selected harmony of materials allows this home to exude an easy vibe, recreating the essence of resort living.<br /><br />With tones borrowed from the sarees, the design story features an interesting incorporation of textiles throughout the house, adding energy and warmth and injecting the joy of living.",
        },
        {
          id: 6,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/1D1tm7uw93AR48L1lnOFqO1EdKk0vkF0I=w1000",
            "https://lh3.googleusercontent.com/d/1rKMqRQCeHINdchIpHzb3FuSUOMbojdCw=w1000",
          ],
          body: "As the residents step inside, a warm foyer welcomes them with wooden columns accented in turquoise blue. Creating a lasting first impression is the vermilion-coloured wall panel housing a beautiful idol of a Goddess.<br /><br />The hints of natural wood finish ground the vibrant palette and radiate warmth. Stepping further in, tropical vibes consume the space, evoking a sense of serene ambience. Artisanal crafts and decor items sustain the couple’s love for personalising spaces uniquely.",
        },
        {
          id: 7,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/1iAIfxJ9c0lI347Vy8oR2gU4h-vZDjMsD=w1000",
            "https://lh3.googleusercontent.com/d/14g-8wto2TEjVXRRMDDxMLrU3jgRpmDv-=w1000",
          ],
          body: "Reflecting the client’s Buddhist practices, the living room features a gong. When struck, the sound vibrates, commotion recedes, and calm fills the space. Within the space, the faux columns are strategically planned to frame a decorative mirror, marrying design with art.<br /><br />One of this home’s most magical assets is the intricately carved sliding-folding panel for the TV. The carving's intricacy elevates the house in terms of elegance and finesse. The feature wall in the dining area serves as the home’s centrepiece, furthering the Bali-inspired experience with a large sculptural light crafted from banana fibre.",
        },
        {
          id: 8,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/1wMFftUBLd6q705u2VrUPSDpTizj8FCel=w1000",
            "https://lh3.googleusercontent.com/d/1GbG8XK4aaU-5GHwj3px_4ddHuMxfLn9S=w1000",
          ],
          body: "The client’s clarity in design vision prompted a vibrant colour scheme. Bold accents, nature-inspired elements, and carefully selected materials culminate in spaces that truly resonate with the client’s sense of joy and comfort.<br /><br />The Pavilion is a testament to the joy of living off the field, crafting a profoundly personalised and elegant sanctuary.",
        },
      ],
    },
    ishavasya: {
      mainImage:
        "https://lh3.googleusercontent.com/d/1oTUFZ9ZWSR8e6ESgKfVD8VYvdeme4HKj=w1000",
      title: "Ishavasya, where the Lord resides",
      caption: "A journey of self-discovery and enlightenment.",
      subtitle: ["Luxury Residence", "Bangalore"],
      info: [
        {
          main: "AREA (Site)",
          subtitle: "2600 sq. ft.",
        },
        {
          main: "Status",
          subtitle: "Completed",
        },
      ],
      sections: [
        {
          id: 2,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/1_acVXlRRsUh8AVrBaNzTPRYgBRcqEFdd=w1000",
            "https://lh3.googleusercontent.com/d/1goDzIE9DJcVU8zwNZxxb_-P_BfpaiC3E=w1000",
          ],
          body: "After residing and working in the US as a tech visionary, the client envisioned a second home in India– 2600 sq. ft. within the Svasa Homes complex. He devised a simple brief to build a home as his offering to the Lord. In the ethereal realm of Ishavasya, the sacred and sublime converge, resulting in a home with an essence of deep reverence.",
        },
        {
          id: 3,
          imageTop: true,
          images: [
            "https://lh3.googleusercontent.com/d/1ZM0h39IKybnexCrXcV2QmpbUyJ4KO1-1=w1000",
          ],
          body: "The main inspiration for the design came from the client’s profound sense of gratitude. The brick-and-mortar bareshell displays the story of faith and devotion through hues, patterns, and motifs. Infusing elements from the mythological tales are the scenes from epics adorning the wall.<br /><br />A unique creative vision led to the introduction of sacred symbols through motifs into every essence of the space. The home reflects an immersive symphony of symbolism, where every minute detail narrates the story of faith and devotion.",
        },
        {
          id: 4,
          imageTop: true,
          images: [
            "https://lh3.googleusercontent.com/d/1Yd_B6x-OcUmCEsPVItf4BycXn9tSi6aP=w1000",
            "https://lh3.googleusercontent.com/d/16xIARUtBtHu9Dqy_w-WcCvkVYrWeUaCX=w1000",
          ],
          body: "",
        },
        {
          id: 5,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/1JNrdmr3g6UMnGU1kUBZCBSrWWvdMwRrY=w1000",
          ],
          body: "Three influences defining devotion in the home are the artworks reflecting epics, Kota and Kadapa stone flooring borrowed from the temple’s sacred ambience, and scenes from mythology as art panels.<br /><br />When it comes to evoking cultural eclecticism, the challenge remains in the execution. With attention to detail and meticulous craftsmanship, from the woodwork to stone mosaic, mother-of-pearl inlay to hand-embroidered zardozi designs, every element showcases a deep commitment to the premium standards of artistry.",
        },
        {
          id: 6,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/1h4KdVOXzm7ukw8NEf-8XOVgUiwwvWWhU=w1000",
            "https://lh3.googleusercontent.com/d/1km48OjvK0xs5OpwCFbPoTEiEyXEHh0pj=w1000",
          ],
          body: "As the residents step inside, an art piece of Lord Hanuman fondly carrying Lord Rama upon his shoulders greets them. The art and its symbolic vermilion hue reflecting a divine connection set the tone for what is to follow throughout this home.<br /><br />Each weathered groove and grain of sand in temple flooring bears the marks of devotion. In that sense, the home features flooring with a similar essence to that of a weather-beaten temple premise.",
        },
        {
          id: 7,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/1huvA661HDrA26iFYnC_BZCRD9TDR9fbc=w1000",
            "https://lh3.googleusercontent.com/d/1nYLppCs29wcI0vyB1bURULXa8kbrAeH0=w1000",
          ],
          body: "Marigold flowers with petals have always been the centre stage of Hindu rituals and ceremonies. Captivating the same essence and symbolising unwavering devotion, purity, and prosperity are the marigold-form drop lights– Jhumki from Klove Studio hosted in the living area.<br /><br />As you walk further into the bedroom, a scene from The Gajendra Moksh episode serves as a captivating art panel on the wardrobe front. The perfectly blended tones with the warm wooden accents evoke a sense of timeless design through modest materials.",
        },
        {
          id: 8,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/1Or5k3RW8MQ3Cs2mwoJetYlcXccgAvGie=w1000",
            "https://lh3.googleusercontent.com/d/1_vE5VnRquz_vY0vYLUcZmFvAqDWvtCJp=w1000",
          ],
          body: "In this holy abode, the divine resides in every corner.<br /><br />From the symbolic episode for spiritual truth and transcendence as an art panel to the welcoming art reflecting an inseparable union between the devotee and divine, it’s all hidden in plain sight, waiting to be uncovered by the discerning eye to spot the narrative woven with threads of reverence.",
        },
      ],
    },
    "beach-house": {
      mainImage:
        "https://lh3.googleusercontent.com/d/1zng5-SFU_VrVkCTlkKspkFjDwBckW3aZ=w1000",
      title: "Beach House",
      caption: "To have done less is indeed to have done more!",
      subtitle: ["Luxury Residence", "ECR, Chennai"],
      info: [
        {
          main: "AREA (Site)",
          subtitle: "10,000 sq. ft.",
        },
        {
          main: "Status",
          subtitle: "Completed",
        },
      ],
      sections: [
        {
          id: 2,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/1wKNvyHS2Z2PoyTMW5A4CxRIQFlQEr1SS=w1000",
          ],
          body: "A home away from the chaos to slow down… of the sand, sun, and sea.<br /><br />When this young couple approached us to add design magic to their homes, we were enchanted by their philosophy of the home's true meaning. Beach House, a 10,000 sq. ft. 5-bedroom property, is nestled off the ECR, Chennai, skirting the Bay of Bengal.",
        },
        {
          id: 3,
          imageTop: true,
          images: [
            "https://lh3.googleusercontent.com/d/1-BP3EsXSyrCdD2yFaJOHV159Yo7MFYvE=w1000",
          ],
          body: "The design goal centered on calming comfort to elevate envisioned indulgences. Emphasizing muted colors and simplified furnishings—hallmarks of tranquility—created a sensory experience permeating the entire space. Within the calm haven sits the home studio of Shweta– BAHA, a footwear label. The serenity shaping up the home is a nearly transcendental experience for her creative process. ",
        },
        {
          id: 4,
          imageTop: true,
          images: [
            "https://lh3.googleusercontent.com/d/1pAwW9ipbA1pRs6ZvBXyTEan4iLTK412e=w1000",
          ],
          body: "",
        },
        {
          id: 5,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/11iLe8UGBXVK4PadN3EmqhmwQWTuROJvX=w1000",
          ],
          body: "The Beach House is the epitome of subtle luxuries. A tone-on-tone palette, marble flooring, and high-quality finishes craft a refined visual narrative. Design languages attempt to segregate entertaining spaces from private spaces. While contemporary design flair adorns common zones, Victorian elegance graces private rooms—yet maintaining a seamless transition. Large glass doors and windows keep up with the continuity of flow by blurring the lines between the outdoors and indoors.",
        },
        {
          id: 6,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/16Nmr6YTpWDOwzgFsboXQIWmLI1DYGRs2=w1000",
            "https://lh3.googleusercontent.com/d/1g4O-Bq4C4fMl9eJCimNApR57GXPNwiup=w1000",
          ],
          body: "Greeting the residents right at the entrance is the swimming pool– cool, refreshing, and setting a tone for the sensory experience waiting to unfold. A double-height scale calms the senses as one leads to the living room.<br /><br />Much of the space is expressed in a muted palette of beige tones, with simplified furnishings punctuating the serenity. Refined simplicity and harmonised textures celebrate the senses throughout.",
        },
        {
          id: 7,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/12b3IspCpK2yNqtasKlmkDxGABzZdmzdu=w1000",
          ],
          body: "The living area establishes a tactile comfort through plush upholstery. Sublime linen curtains convey subtle elegance while mimicking the coastal essence. An intentional colour palette with gentle white, pale blue, sandy tones, etc., keeps the home wrapped in serenity while perfectly mirroring the beachscape.<br /><br />Clean lines and understated forms of furnishings play host to the natural beauty of materials and simultaneously promise comfort. Mindful of maintaining a connection with nature, the double-height glass in the living room frames the swimming pool view while allowing sunlight in.",
        },
        {
          id: 8,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/19kwJQC6xX5-AnW3ZN_9uzN8WyCjlK4IA=w1000",
            "https://lh3.googleusercontent.com/d/1RXIsh8HdTjru3T5gZSbakTdeJCWpQ-Mq=w1000",
          ],
          body: "The main inspiration for Beach House stemmed from the desire to curate a comfortable and sensory living experience. Soft hues and refined simplicity break the rank with the ostentatious, focusing instead on a serene and sensory retreat.<br /><br />This luxury residence offers a sensory haven and precisely reflects the client’s vision of a home embodying peace, elegance, and understated luxury.",
        },
      ],
    },
    essencia: {
      mainImage:
        "https://lh3.googleusercontent.com/d/1tSijlUWo7m5VDXLwlg3cbZx1d3OXFlml=w1000",
      title: "Essencia, For the Senses",
      caption: "For a sheer indulgence in bliss, this.",
      subtitle: ["Luxury Wellness", "Basavangudi, Bangalore"],
      info: [
        {
          main: "AREA (Site)",
          subtitle: "1600 sq. ft.",
        },
        {
          main: "Status",
          subtitle: "Completed",
        },
      ],
      sections: [
        {
          id: 2,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/1ZvdjaOVm9Yre8MiYyh_ZCP4LkvSHbOjQ=w1000",
            "https://lh3.googleusercontent.com/d/1IxBZKH1PazRybVtwUSLy92MIIrCiQEqM=w1000",
          ],
          body: "Striking the right balance between the traditional and luxury is more complicated than it appears. This luxury wellness space, sprawling across 1600 sq. ft. in Basavangudi, Bangalore, however, flawlessly manages it through an opulent and modern Indian theme– Shringhar. Nestling in a neighborhood rich in culture, Essencia establishes itself as a landmark through its visual narratives.",
        },
        {
          id: 3,
          imageTop: true,
          images: [
            "https://lh3.googleusercontent.com/d/1HwwWvxFX6Qc2mpnaI_19zWBTSfiuYYi5=w1000",
          ],
          body: "One of the most critical aspects of design is getting on the same mental wave as the clients. Poised to step into the wellness industry, our clients wished to launch themselves as a unique brand, and our team managed to serve them beyond expectations.<br /><br />Our design offerings for this project were derived from market research and experience in the wellness sector. The peculiarities of the site location—vantage point and cultural richness—guided the project’s conceptualization. We envisioned a palatial ambiance that conversed with the site specificity for opulent spatial dialogues.",
        },
        {
          id: 4,
          imageTop: true,
          images: [
            "https://lh3.googleusercontent.com/d/1e7TPj9jSAhm6ucGqDtuUCtUHYtAIcCF9=w1000",
          ],
          body: "",
        },
        {
          id: 5,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/1EYulD4lhTNPPTyLgyqLT7fYa2xxtdg3f=w1000",
          ],
          body: "We chose to break away from a conventional beauty salon’s modern glam ethos. Our design philosophy for this luxury salon boasts the ancient Indian art of ‘Shringhar.’ The salon is steeped in the celebration of traditional Indian craftsmanship.<br /><br />Etched mirrors, Benaras sold fabric as wall art, and intricately carved wooden moldings gesture the influence of Indian culture. Painting a clear picture of timeless essence and elegance further, ‘Shringhar’ takes over as the signature style for the interiors.",
        },
        {
          id: 6,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/1sR6zTXvejC6a1br09ECgNIKplF3Hsht4=w1000",
          ],
          body: "Standards for function and smooth operations guided the spatial layout. From inception to installation, meticulous attention to detail and seamless blending of types of equipment in the layout remained our top priority. A palette rich in woody browns and adorned with Indian floral motifs harmoniously brings together function and traditional warmth, curating an immersive sensory experience.",
        },
        {
          id: 7,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/1TtLYfvdy15MxvskkU4wq02zbOCuVm_IJ=w1000",
          ],
          body: "An intriguing glass facade invites the onlookers to enter luxury through the hide-and-seek effect of sheer curtains. A grand double-leaf entrance door ultimately unfolds an opulent and modern Indian retreat.<br /><br />A framed Benaras silk fabric catches your attention as you enter the reception. The Nail Bar, strategically positioned by the reception, evokes an enchanting allure of fireflies. Radiating this essence is a patterned etched mirror for mesmerizing appeal. Infinite reflections from the mirrors solidify the place of stunning grandeur in the salon.",
        },
        {
          id: 8,
          imageTop: true,
          images: [
            "https://lh3.googleusercontent.com/d/1A2EEqyC8D5YatvAVaCnL8rJD-74I1Hbi=w1000",
          ],
          body: "When it comes to Indian craftsmanship, the critical element is precision. Our team’s rigorous quality control and close coordination with the artisans promised a tactile translation of the intuitive juxtaposition– traditional over contemporary.<br /><br />Our focus for Essencia was to design for the senses. Our approach focused on blending boundless creativity while strategizing based on insights. The touchdowns on artistry, innovation, and elegance allowed us to add a new chapter to the art of crafting luxury wellness.<br /><br />From a warm palette to shiny mirrors, Essencia serves as an aesthetic narration of the client’s aspirations and our expertise.",
        },
      ],
    },
    "ode-to-timber": {
      mainImage:
        "https://lh3.googleusercontent.com/d/1YYW19VEkzwq6uzZ_Q4eYpKrlRkweit1y=w1000",
      title: "Our Ode to Timber at Villa N 17",
      caption: "Manifesting timber and its subtle charm.",
      subtitle: ["Row Villa", "Bangalore"],
      info: [
        {
          main: "AREA (Site)",
          subtitle: "5500 sq. ft.",
        },
        {
          main: "Status",
          subtitle: "Completed",
        },
      ],
      sections: [
        {
          id: 2,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/1oFckUcMAPllPSy_RlvS6yFMgryHkJPzT=w1000",
            "https://lh3.googleusercontent.com/d/1Ap9ydJdkVaFR8JozPucv9BLcWq1uRc5z=w1000",
          ],
          body: "Each home demands a unique identity—characterized by its owner's personality. At Villa N 17, the spaces connect honestly to the client’s aspiration. As one steps inside, the home reveals his decade-long passion for timber. Every design decision for this 5,500 sq. ft. sanctuary infuses authenticity and warmth into the house.",
        },
        {
          id: 3,
          imageTop: true,
          images: [
            "https://lh3.googleusercontent.com/d/1isdrm2r5upb6TE9pejEp10N1ey6v5z-C=w1000",
          ],
          body: "An unplastered bare shell– this was the starting point of our ode to timber. What followed was the charming playfulness of wood in its many forms. Every art, texture, accessory, and design element overlaps with a distinct design flavor celebrating timber.<br /><br />This personal sanctuary hints at timber's simplicity, warmth, and versatility through contemporary Indian aesthetics. The elegance in spaces pays homage to the owner’s passion, and every element tells a story.",
        },
        {
          id: 4,
          imageTop: true,
          images: [
            "https://lh3.googleusercontent.com/d/1B6jxxoZI_rVnHB9mQNAUGgzmy9hsmpqO=w1000",
          ],
          body: "",
        },
        {
          id: 5,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/1h8BpsRMwJShVqteayKYTNCDDLy2NOpnf=w1000",
          ],
          body: "From bold to subtle, each space expresses timber in a unique language. Indian contemporary charm allows the wood to sit in peaceful cohesion with neutral tones. Along with timber entering the spotlight, nature-inspired furniture comes into play—as a testament to function and aesthetics.<br /><br />The exceptional form results in a visually stunning and equally comfortable design.",
        },
        {
          id: 6,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/1u4hgTZnbd-9uV7VSdcZw-FuH2hTQ6ost=w1000",
          ],
          body: "The timber story unfolds as one steps into the living area—a double-height space that speaks all about grandeur. The wall paneling adorning the height narrates an intricate, interlaced tale of elegance. While the living area makes a bold impression, the family room, on the other hand, balances it out.<br /><br />Staying true to the overall decor, the family room is enlivened by a feature wall with an interplay of stone, quietly complemented by a wooden ceiling.",
        },
        {
          id: 7,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/1yuP3Qt7N7tuw5r96k6u9V7HMG9xijZGy=w1000",
          ],
          body: "This space allows regular chairs to be replaced by sculptural leaf chaises. Carved out of solid timber, the organic design reminiscent of veins and branches of a leaf imparts a sense of sophistication to the room while keeping comfort uncompromised.<br /><br />This unique silhouette fits beautifully into the family room, adding a touch of natural elegance and sophistication. With myriad natural influences—through materials and form—blending lovingly into the house, elegance takes center stage.",
        },
        {
          id: 8,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/1zP0zxxrDwKg6eXfEyFyyc008htLl7hK7=w1000",
          ],
          body: "Tucked in the backyard is an open-to-sky bar that evokes allure and fosters a connection with nature. The concrete bar counter, crafted by Nuance Studio, solidifies the personal touch through the carved Glenfiddich logo. It reflects the client’s personal taste through thoughtful craftsmanship.<br /><br />The bar features a cascading water feature, which creates a sense of dynamicity and unwinding quotient. This oasis of tranquility enables a simple visit to transform into an experience with a collection of moments and memories.",
        },
        {
          id: 9,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/1BT3KYSjKtBiGyWLB4Jy-aWa2ALnuD8Mm=w1000",
            "https://lh3.googleusercontent.com/d/16G7NYLggfs-I24iBTe_KZGm_EA-L8a__=w1000",
          ],
          body: "As one steps into the master bedroom, seeking a retreat from the everyday hustle, warmth welcomes them. A fine veneer bed back and tan-brown leather headboard offer a posh retreat that’s comfortable, stylish, and exudes luxury.<br /><br />Echoing the contemporary spirits, the ensuite washroom showcases the latest Gessi fittings. A large glass panel doubling as a partial mirror keeps the design visually appealing, connected, and secure.",
        },
        {
          id: 10,
          imageTop: false,
          images: [
            "https://lh3.googleusercontent.com/d/1wQEGKRI4P28tTJzEYCNKPeLWiYurrA2s=w1000",
            "https://lh3.googleusercontent.com/d/1YYW19VEkzwq6uzZ_Q4eYpKrlRkweit1y=w1000",
          ],
          body: "The generous use of wood in the guest room offers quality hibernation. A sleek slatted ceiling design keeps the design simple yet classy.<br /><br />From wood on walls to wood as art, the presence of timber weaves a story for this timeless home. Villa N17 nails the art of leaving an enduring impression and offers a glimpse into the client’s taste at every corner.",
        },
      ],
    },
    "kimera-wellness": {
      comingSoon: true,
    },
    "new-york-loft": {
      comingSoon: true,
    },
    "a-closet": {
      comingSoon: true,
    },
  },
  blockFourCards: [
    {
      title: "Card 1",
      image:
        "https://lh3.googleusercontent.com/d/11a6MjgBH4kB2vY5I5OVBHd0iauL_VZCh=w1000",
      smallText: "Our Chronicles",
      largeText: "7 Luxury Interior Design Trends that will be leading in 2025",
      url: "/chronicles/interior-design-trends",
    },
    {
      title: "Card 2",
      image:
        "https://lh3.googleusercontent.com/d/1ywV4bYAuxAxiOCPGJjuDl-HN1RQI7L92=w1000",
      smallText: "Our Chronicles",
      largeText: "A Day in the Life of an Interior Designer",
      url: "/chronicles/life-at-spellbound",
    },
    {
      title: "Card 3",
      image:
        "https://lh3.googleusercontent.com/d/1wQxQ9xibWoH7vG8JwAT1YvrMAat2Cyz8=w1000",
      smallText: "Our Chronicles",
      largeText: "Curating the Art of Space: Top 5 Signature Projects",
      url: "/chronicles/signature-projects",
    },
    {
      title: "Card 4",
      image:
        "https://lh3.googleusercontent.com/d/1ilmlg1ETgC5xnGy9HwBAi-vpMDayDpzo=w1000",
      smallText: "Our Chronicles",
      largeText: "Designing for All Senses",
      url: "/chronicles/sensory-design",
    },
  ],
  blockThreeSlides: [
    {
      url: "/interior/ishavasya",
      title: "Ishavasya",
      image:
        "https://lh3.googleusercontent.com/d/1padtLRouIN6lLQlg1BSUXKQvkqkNr-lZ=w1000",
    },
    {
      url: "/interior/the-pavilion",
      title: "The Pavilion",
      image:
        "https://lh3.googleusercontent.com/d/1l3aHsUmz-iT7x27p8y-VTAlWLF7wiFtw=w1000",
    },
    {
      url: "/interior/ishavasya",
      title: "The Holy Abode",
      image:
        "https://lh3.googleusercontent.com/d/1nYLppCs29wcI0vyB1bURULXa8kbrAeH0=w1000",
    },
    {
      url: "/interior/beach-house",
      title: "Beach House",
      image:
        "https://lh3.googleusercontent.com/d/1zng5-SFU_VrVkCTlkKspkFjDwBckW3aZ=w1000",
    },
    {
      url: "/interior/ode-to-timber",
      title: "Godrej N17",
      image:
        "https://lh3.googleusercontent.com/d/1zP0zxxrDwKg6eXfEyFyyc008htLl7hK7=w1000",
    },
    {
      url: "/interior/essencia",
      title: "Essentia Wellness",
      image:
        "https://lh3.googleusercontent.com/d/1HwwWvxFX6Qc2mpnaI_19zWBTSfiuYYi5=w1000",
    },
  ],
  chroniclesBlock: [
    {
      title:
        "ARTICLE 01_8 Luxury Interior Design Trends that will be leading in 2025",
      image:
        "https://lh3.googleusercontent.com/d/11a6MjgBH4kB2vY5I5OVBHd0iauL_VZCh=w1000",
      url: "/chronicles/interior-design-trends",
    },
    {
      title: "ARTICLE 02_Designing for All Senses_Spellbound",
      image:
        "https://lh3.googleusercontent.com/d/1ywV4bYAuxAxiOCPGJjuDl-HN1RQI7L92=w1000",
      url: "/chronicles/life-at-spellbound",
    },
    {
      title:
        "ARTICLE 03_Curating the Art of Space: Top 5 Signature Projects_Spellbound",
      image:
        "https://lh3.googleusercontent.com/d/1wQxQ9xibWoH7vG8JwAT1YvrMAat2Cyz8=w1000",
      url: "/chronicles/signature-projects",
    },
    {
      title: "ARTICLE 04_A Day in the Life of an Interior Designer_Spellbound",
      image:
        "https://lh3.googleusercontent.com/d/1ilmlg1ETgC5xnGy9HwBAi-vpMDayDpzo=w1000",
      url: "/chronicles/sensory-design",
    },
  ],
  chronicesBlogs: {
    "interior-design-trends": {
      id: 1,
      title: "7 Luxury Interior Design Trends that Will Be Leading in 2025",
      subtitle:
        '<span class="text-special">With the power of luxury we unveil seven cutting-edge interior design trends poised to redefine elegance and comfort in 2025.</span>',
      image:
        "https://lh3.googleusercontent.com/d/1nYLppCs29wcI0vyB1bURULXa8kbrAeH0=w1000",
      caption:
        "Reimagining Indian luxury with homes that deeply connect with their roots.",
      body: "In design, luxury is no longer only about expensive accessories, gilded elements, and premium materials. The latest interior design trends are about solidifying the place of ‘emotions’ and ‘experiences.’<br /><br />Over the years, our lifestyles have undergone significant changes. Today, consumers’ lifestyles and interior design are more intertwined than ever. Spaces serve as an elegant narration of users’ worlds. Interior design trends are evolving as a journey to strike human aspirations.<br /><br />As we head towards 2025, luxury interiors are weaving within the stories and innovation to create a lasting impression. With consumers expecting ‘more’ from their spaces, new interior design trends are doing more to cater to the growing desires of living, working, resting, and playing immersively.",
      blocks1: [
        {
          id: 11,
          title:
            "Let’s take a closer look at what’s on its way in for the latest interior design trends in 2025.",
          subtitle: "",
          image: "",
          caption: "",
          body: "",
          blocks2: [
            {
              id: 111,
              title: "1. Building Spaces that Immerse: Sensorial Spaces",
              subtitle:
                "People crave comfort– not just physical but emotional, too. This is where the new interior design trends will present an opportunity to address the senses.",
              image:
                "https://lh3.googleusercontent.com/d/11iLe8UGBXVK4PadN3EmqhmwQWTuROJvX=w1000",
              caption: "Feasting the senses through neutrals and naturals.",
              body: "Sensorial design trends will establish a connection with multiple senses through textures, scents, lighting, sound, and visual appeal. Imagine stepping into a room with soft and tactical fabrics thrilling the fingerprints. A subtle aroma infused in the air and lighting mimicked nature’s shift. That’s how a multi-sensory approach in design is seen and ‘felt.’<br /><br />The floor, ceiling, and walls can adopt vibrant tones and textural materials to create mindful spaces that allow users to pause, reflect, and reconnect with their inner selves.",
              blocks3: [],
            },
            {
              id: 112,
              title: "2. Welcoming Technology to Daily Lives",
              subtitle: "",
              image: "",
              caption: "",
              body: "We no longer live in the manual era. Today, the technology works on your command. From controlling the lighting to adjusting spaces to a comfortable temperature– smart gadgets do it all.<br /><br />Technology is slowly becoming a household necessity, hinting at smart homes. The latest interior design trends focus on ‘domesticated’ designs for tech products to blend seamlessly with the spaces.<br /><br />Sleek and shiny gadgets are favoring aesthetics and making ther way to home.",
              blocks3: [],
            },
            {
              id: 113,
              title: "3. Spaces that Empathize",
              subtitle: "",
              image: "",
              caption: "",
              body: "Design impacts.<br /><br />It represents healing and positivity. With growing complexities and needs, designers will compassionately make sense of challenges for lasting solutions.<br /><br />An empathetic approach will keep the design ‘humanized.’ Putting people at the core, the details will respond to the evolving physical, cognitive, and sensory needs, crafting a ‘comfortable’ environment.<br /><br />The key elements an empathetic design will address are:<br /><br /><ul><li>Accessibility and Visibility</li><li>Health and Safety</li><li>Comfort and Convenience</li><li>Inclusivity.</li></ul><br />From integrating versatile, easy-to-move, and open furniture to mono-neutrals evoking calm, the latest interior design trends’ impact on holistic well-being will be a critical consideration for luxury sanctuaries.",
              blocks3: [],
            },
            {
              id: 114,
              title: "4. Manifesting Quiet Luxury in Spaces",
              subtitle:
                "Interior designers will allow luxury to speak for itself. Quiet luxury offers a refreshing contrast to the otherwise blingy displays.<br /><br/>It’s more about appreciating quality, finesse, and elegance through classic forms, soft and textured fabrics, quieter patterns, and the warmth of wood.",
              image:
                "https://lh3.googleusercontent.com/d/1l3aHsUmz-iT7x27p8y-VTAlWLF7wiFtw=w1000",
              caption:
                "Passionate spaces embodying harmony, beauty, and quiet luxury.",
              body: "This interior design trend will focus on authenticity, allowing every design element to add meaning to the space and contribute to the sense of harmony. Quiet luxury is about a deeper and more personal connection to the elements we infuse in the interiors.",
              blocks3: [],
            },
            {
              id: 115,
              title: "5. Introducing Cheer with Colours",
              subtitle:
                "Interior design is a mirror that reflects the user’s personality. Nothing helps better than colors to add a dash of your personal touch.<br /><br />The new interior design trends of 2025 will feature unexpected bursts of color to elevate the mood. The power of a palette can effortlessly add style and joy to everyday life.<br /><br/>Let’s look at the top shades that will shape the luxury designs:",
              image:
                "https://lh3.googleusercontent.com/d/1jx5mWxAgfBGU-9wMoT2tf7z9MqdvSnjN=w1000",
              caption: "",
              body: "",
              blocks3: [],
            },
            {
              id: 116,
              title: "6. Adding Comfort through Curves",
              subtitle:
                "Curves have always been a thing of beauty in interiors. Free-flowing forms are set to redefine interior spaces by ushering in a wave of elegance and comfort. Organic shapes will be adopted to add an ‘airy feel’ and hint at the sense of motion.",
              image:
                "https://lh3.googleusercontent.com/d/1_qRU8a9vNkrTtKmbrb-cT6a3ycChTzEy=w1000",
              caption:
                "Playful twists and turns elevating the comfort of the Rattan sofa.",
              body: "Soft edges, organic forms, and plush seating will lead the way in inviting users to soak into relaxation. These curved lines, resembling the calming forms of nature, will introduce coziness and harmony to the interior design. Additionally, hinting at biophilia, free-flowing forms will intrigue the room.",
              blocks3: [],
            },
            {
              id: 117,
              title: "7. Purpose per Square Foot",
              subtitle: "",
              image: "",
              caption: "",
              body: "Remote working is staying despite the constant nudging to work from the office. People are no longer settling for compromised workspaces. The trending interior design styles will return to broken yet flexible layouts that delineate purpose.<br /><br />From versatile shelving to wall hangings that double as room dividers, homes will use more than one function per square foot.",
              blocks3: [],
            },
          ],
        },
        {
          id: 12,
          title: "Crafting Spaces that Resonate with Emotions",
          subtitle: "",
          image: "",
          caption: "",
          body: "Current interior design trends are shaping spaces that offer an unforgettable experience. Luxury in interior design is about crafting spaces that resonate with the user’s emotions and personality.<br /><br />From empathetic solutions to integrating advanced technology, interior designers will redefine what it means to live in luxury!",
          blocks2: [],
        },
      ],
    },
    "sensory-design": {
      id: 2,
      title:
        "Designing for All Senses: How is Sensory Design Culminating Mundane Spatial Musings into Whimsical yet Tangible Designs?",
      subtitle:
        '<span class="text-special">With the art of sensory design, where every element transcends mere visual appeal to create spaces that resonate with all senses.</span>',
      image:
        "https://lh3.googleusercontent.com/d/1zpZS5UdIWNIPQhLO5axJ_Uf3Ri6Q4kFx=w1000",
      caption:
        "A holistic design goes beyond the confines of what pleases the eye and inundates all our senses in tune with each other.",
      body: "In the words of Finnish architect Juhani Pallasmaa, “Architecture is the art of reconciliation between ourselves and the world, and this mediation takes place through the senses.” Take a moment and get your brain cells running—have you ever encountered a spatial design that envelopes you in its magic the moment it meets your eye?<br /><br />The aesthetics are poetic, weaving a magical narrative, but you are left wondering—what’s more to this oh-so-spectacular space that keeps drawing me in?<br /><br />A well-crafted space goes much beyond the confines of the visual spectrum. Yes, it’s a must for a design to please the eyes, but is that all?<br /><br />Even though most of our design aspirations are realised as “you get what you see,” designing a space that makes your senses interact with your surroundings requires much more than experimenting with the whimsies of design aesthetics.<br /><br />Enters <b>sensory design.</b>",
      blocks1: [
        {
          id: 21,
          title: "The Concept At Play",
          subtitle: "",
          image:
            "https://lh3.googleusercontent.com/d/1LasNU-RW2KU0fs6jv353QbVfk3wnZVbS=w1000",
          caption:
            "A space that doesn’t just make you but also your furry babies stop and stare.",
          body: 'As a concept, sensory design—its notion, essence, experience, and culmination—is all based on cocooning us in an immersive sensory interaction. A space tailored to offer a little something for all senses can trigger multiple feelings, such as warmth, comfort, excitement, surprise, and even fear.<br /><br />Sensory designing is a layered concept where multiple elements are at play, infusing life into the sensory serenade and bringing it to a tangible realisation. This encompasses textures, smells, and sounds, which come together to form an enchanting and interactive design palette.<br /><br /><span class="text-normal">Imagine flipping through the crisp pages of your favourite read on a monsoon afternoon. Would you like to be enveloped within four bland walls, struggling to make sense of the words in dim lighting? No, right?</span><br /><br />Instead, think of a warmly lit room where the scent of fresh blooms intertwines with the strong aroma of your coffee. The soft rustling of the curtains and the warmth of the wooden floors perfectly connect you to the rhythm of the raindrops, engrossing you more in the story while you relish the sensory design narrative embracing you.',
          blocks2: [],
        },
        {
          id: 22,
          title: "What Meets the Eye",
          subtitle: "",
          image:
            "https://lh3.googleusercontent.com/d/1h4KdVOXzm7ukw8NEf-8XOVgUiwwvWWhU=w1000",
          caption: "Colours let a space whisper its own whimsical tales.",
          body: "As stewards of achieving human comfort through well-thought-out design, architects must achieve a sweet spot between balancing natural light, colour schemes, and ambient lighting with their designs.>br /<br />Experiment with colours that let the space speak its story rather than overpower its charm. Different tones are associated with different moods and feelings. Channeling the appeal of various hues can help designers impact end-users and how they perceive a certain spatial unit.<br /><br />For instance, you can create the sight of a rainbow by mixing vibrant colours for a kid’s play area. On the contrary, you can beat the blues with bright reds that exude an energetic appeal.",
          blocks2: [],
        },
        {
          id: 23,
          title: "In Tune with the Sounds of Design",
          subtitle:
            '<span class="text-normal">How often do we come across holistic designs that explore sound in architecture beyond the mundane concepts of soundproofing and noise reduction?</span><br /><br />While those might be important checkboxes to accomplish a pragmatic design, it doesn’t end there.<br /><br />Different sounds entail the ability to make your design dance to different tunes. The tranquil flow of water can make for magical background music for practising mindfulness meditation. Contrastingly, energetic and upbeat tunes can imbibe euphoria, weaving together the perfect ambiance to relish an exciting party.',
          image:
            "https://lh3.googleusercontent.com/d/16lT6H_XRW8YPAq_E4OQNg6sW_rFTv1Bx=w1000",
          caption:
            "The pool adorning the entrance of our Beach House in Chennai epitomises the playful interplay of the sun, sand, and sea.",
          body: "",
          blocks2: [],
        },
        {
          id: 24,
          title: "Smells that Teleport You",
          subtitle:
            '<span class="text-normal">Isn’t it surprising how different smells have the power to remind you of a specific moment in life? For instance, the delicious smell of that favorite candy from childhood brings back those cherished school memories.</span>',
          image:
            "https://lh3.googleusercontent.com/d/1HwwWvxFX6Qc2mpnaI_19zWBTSfiuYYi5=w1000",
          caption:
            "Beyond the aesthetics, this salon envelopes you in a sensory delight awaiting to unfold itself, one little surprise at a time.",
          body: "Smells not only entail the ability to become magical reminders of specific life moments but also help navigate spaces in ways other than merely visually. Inculcating scents within the design narrative is an excellent way of etching memories in the user’s mind that make your design establish an emotional connection with them.<br /><br />Besides, fragrances, when infused wisely, can help visually challenged people navigate through their surroundings with ease.",
          blocks2: [],
        },
        {
          id: 25,
          title: "A Vision You Can Touch",
          subtitle:
            "The most vital and relevant element of a multi-sensory design experience is touch.",
          image:
            "https://lh3.googleusercontent.com/d/1Or5k3RW8MQ3Cs2mwoJetYlcXccgAvGie=w1000",
          caption:
            "Raw finishes, warm tones, and soothing colours—every element in the design dances to its own rhythm.",
          body: "The texture of soft furnishings, the warmth of floors touching your underfoot, and everything in between—every touch point in the design narrative brings the sensory interaction to life.<br /><br />Every element you add to your design palette should be well-thought and designed, not just in resonance with the aesthetic but also the textures.",
          blocks2: [],
        },
      ],
    },
    "signature-projects": {
      id: 3,
      title: "Curating the Art of Space: Top 5 Signature Projects",
      subtitle:
        "<span class=\"text-special\">In the heart of spaces lie stories waiting to be told.</span><br /><br />Sometimes, it is a historical tale and, at times, a modern narrative. Despite the 'modernity' surrounding us, there's no denying the fact that we're always longing for the past yet craving modern comfort in interior design.<br /><br />At Spellbound, we know how to establish a delicate balance creatively. Rooted in the belief of adding ‘meaning’ to home interior design, our projects are an ode to the cultural roots in a contemporary context.",
      image: "",
      caption: "",
      body: "",
      blocks1: [
        {
          id: 31,
          title:
            "Here, we share the stories behind our signature projects that turned out as firm believers of contextual essence.",
          subtitle: "",
          image: "",
          caption: "",
          body: "",
          blocks2: [
            {
              id: 311,
              title: "1. Integrating Culture in Interior Design: Ishavasya",
              subtitle: "",
              image:
                "https://lh3.googleusercontent.com/d/1padtLRouIN6lLQlg1BSUXKQvkqkNr-lZ=w1000",
              caption:
                "A grand focus on the right tones makes all the difference.",
              body: 'Culturally, a home symbolizes much more than just bringing a family together. It’s a reflection of the aspirational statement the residents aim at.<br /><br />So was the definition of home for our client– a visionary of the tech world living in California. He manifested a home in Bangalore as his offering to the Lord. Drawing on the theme and paying tribute to the regal roots, Spellbound crafted Ishavasya– The Holy Abode.<br /><br />As one steps in, the chosen hues, motifs, and patterns paint a clear picture of a warm home telling stories of faith and devotion. The generous use of accents like vermilion, brown, gold, and blue focuses on symbolism in the cultural tales.<br /><br />From the welcoming mirror to the rich tapestry adorning the walls, from the living room to the bedroom interior design, the true essence of the Holy Abode is captured in exquisite detail.<br /><br /><span class="text-special">In Ishavasya, the divine resides in every corner.</span>',
              blocks3: [],
            },
            {
              id: 312,
              title: "2. The Story of Design Coherence: New York Loft",
              subtitle: "",
              image:
                "https://lh3.googleusercontent.com/d/1oQE93ux2ToLxBWwWpSZBSiH1o68SY-8e=w1000",
              caption: "It’s a full moon night every night!",
              body: 'Amidst the hustle and bustle of Bengaluru emerges a loft radiating New York’s infectious creative energy. This residence is steeped in maximalism emerging out of minimalism.<br /><br /><span class="text-special">Through the interior design language, The New York Loft evokes the ethos of all things NY!</span><br /><br />The interior layout welcomes you to a living space that speaks the language of subtlety through curvy furniture. The muted base lets design elements and the free-formed furnishings shine through the room.<br /><br />A life-sized print is a veritable canvas of creativity, sliding, and unfolding drama. From quirky lighting features to brushed industrial accents, the mix ‘n’  match approach infuses charm into the interiors.<br /><br />Amidst a conscious interplay of wood, terrazzo, and granite in the dining and kitchen interior design, the ‘moon’ light steals the show, bringing the ‘freshness’ of modern design to the space.',
              blocks3: [],
            },
            {
              id: 313,
              title: "3. Guided by Personal to Craft Personal: The Pavilion",
              subtitle: "",
              image:
                "https://lh3.googleusercontent.com/d/1zpZS5UdIWNIPQhLO5axJ_Uf3Ri6Q4kFx=w1000",
              caption: "Thriving on the beauty of materials and craftsmanship.",
              body: '<span class="text-special">Designed as a retirement sanctuary for a cricket historian, The Pavilion tells a sentimental spatial story.</span><br /><br />It began by capturing clients’ tastes and aspirations through their favorite saree. The approach guided our exclusive choice of tones, patterns, and materials to add emotional depth to the overall design. The retirement home is filled with accents that surprise, inspired by the client’s deep appreciation for Bali aesthetics.<br /><br />Our team at Spellbound broke through the conventional briefing to envision a vibrant home that personally speaks to the residents and extends as a reflection of ‘them.’',
              blocks3: [],
            },
            {
              id: 314,
              title: "4. Less Makes More: The Beach House",
              subtitle: "",
              image:
                "https://lh3.googleusercontent.com/d/12b3IspCpK2yNqtasKlmkDxGABzZdmzdu=w1000",
              caption: "Simplicity speaks an elegant language of its own.",
              body: '<span class="text-special">Thriving on ‘the sun, sand, and sea,’ Beach House is the purest reflection of ‘less is more.’</span><br /><br />Tucked away from the urban chaos, the property is a soothing oasis overlooking the vast waters of the Bay of Bengal.<br /><br />Much of this abode is a canvas in itself– one that frames ‘calm.’ From the pool welcoming you at the entrance to the double-height living room interior design, every element stays far from the flashy. Each nook speaks volumes about quiet luxury and comfort.<br /><br />The understated furniture and muted colors foster a sense of ‘real indulgence’ rather than pretentious spatial drama.',
              blocks3: [],
            },
            {
              id: 315,
              title: "5. Single Material, Endless Forms: Villa N17",
              subtitle: "",
              image:
                "https://lh3.googleusercontent.com/d/1yuP3Qt7N7tuw5r96k6u9V7HMG9xijZGy=w1000",
              caption: "A distinct sense of warmth with wood.",
              body: '<span class="text-special">Villa N17 is a living canvas driven by a deliberate choice– to flaunt timber in every form.</span><br /><br />The mood board evolved from a simple inspiration—the client’s decade-long passion for timber. A material-focused palette reflects the central idea of defining character in every room.<br /><br />From timber as a pattern to timber as an attention-seeking art, the entire home serves as a warm host, letting residents enjoy residing in it every day.',
              blocks3: [],
            },
          ],
        },
        {
          id: 32,
          title: "The Art of the ‘Conscious’",
          subtitle: "",
          image: "",
          caption: "",
          body: "A designer’s mission is not to paint a pretty picture in home interior design. Instead, it's about keeping it visually stunning while adding a more profound meaning and context to every room.<br /><br />Dotted with the quintessential elements from the brief, interior design must tell a story, evoke emotions, and stay genuinely spellbinding.",
          blocks2: [],
        },
      ],
    },
    "life-at-spellbound": {
      id: 4,
      title: "A Day in the Life of an Interior Designer",
      subtitle:
        '<span class="text-special">8 Hours in the Whimsical World of Spellbound</span>',
      image:
        "https://lh3.googleusercontent.com/d/1BTDXTylbHUItesObvFr-quUMrBV_nA2W=w1000",
      caption: "",
      body: 'As the first rays of morning light pierce the Bengaluru cityscape, they warmly illuminate Spellbound’s design studio.  Revealed tales woven with fabric swatches, color palette secrets uncovered, the rhythmic dance of sketching pencils—a series of stories whispers through the air. In this setting, routine is elevated.<br /><br /><span class="text-special">What does a day in the life of an interior designer at Spellbound look like?</span><br /><br />While our final creations are often all you see, the process behind them is rich with unseen effort and creativity. Today, let\'s pull back the curtain and walk you through a typical day at our studio, revealing the art and science behind each design decision.',
      blocks1: [
        {
          id: 41,
          title: "Morning: Dawn of Design (4:00 AM - 6:00 AM)",
          subtitle: "",
          image: "",
          caption: "",
          body: "",
          blocks2: [
            {
              id: 411,
              title: "From Chai to Concepts",
              subtitle:
                "Here, in the bustling studio, a warm cup of chai not only kickstarts the morning but also fuels a vibrant brainstorming session among our passionate designers.<br /><br />Our team gathers around the sprawling oak table, brimming with ideas and sketches.",
              image:
                "https://lh3.googleusercontent.com/d/1h04zHj_jKrwzFqd-cwaozq09liVCaMoh=w1000",
              caption: "",
              body: "The concepts are endless—an Art Deco grace with Mughal miniature, a pop of fuchsia honors the client's passion for Jaipur, and a concealed compartment inside an old armoire adds a whimsical intrigue.<br /><br /><span class=\"text-normal\">These are more than just design choices; they are paintbrush strokes on the canvas of a client's life.</span>",
              blocks3: [],
            },
            {
              id: 412,
              title: "Midday: Concepts into Creations (9:00 AM - 12:00 PM)",
              subtitle:
                "Moving from concept to creation, our designers collaborate closely, ensuring every detail embodies our clients' visions and desires. These sessions are more than just meetings; they are dynamic workshops where creativity flows as freely as the conversations.",
              image:
                "https://lh3.googleusercontent.com/d/1lRdwNM7MQSFgLrAFzgg5aaRgS7Q01hGv=w1000",
              caption: "",
              body: "",
              blocks3: [
                {
                  id: 4121,
                  title: "Coming Alive with Visuals",
                  subtitle:
                    "The design then comes to life in virtual environments. Expert hands convert concepts into breathtaking 3D representations, and here begins the creation.<br /><br />On the other hand, clients are taken to their future homes for constant feedback and self-learning.",
                  image:
                    "https://lh3.googleusercontent.com/d/1u9mzbwtIXsBpiBwScZjvTxQHaOgcK0NN=w1000",
                  caption: "",
                  body: "",
                },
                {
                  id: 4122,
                  title: "",
                  subtitle:
                    "The designers adjust, enhance, and refine, ensuring that the final designs not only meet but exceed expectations. Enchanted spaces come true in this area as clients gasp with joy to see how their uncommon story has been tenaciously blanketed over their future abodes.",
                  image:
                    "https://lh3.googleusercontent.com/d/1W3Dqm1EHYoihgXdGCCHAo9DV2lAIKz6E=w1000",
                  caption: "",
                  body: "",
                },
              ],
            },
            {
              id: 413,
              title: "Afternoon: The Magic of Realization (12:00 PM - 3:00 PM)",
              subtitle:
                'The afternoon hums with the energy of site visits followed by a lunch break where we all gather to share our day’s stories and experiences. The designer visits the raw space with mood boards and swatches, sensing the promise.<br /><br /><span class="text-special">This is where Spellbound\'s magic takes shape—the idea becomes a physical reality.</span>',
              image:
                "https://lh3.googleusercontent.com/d/1w9jN-EB7p7nxno8voTz5DFFyh_Y8zuqq=w1000",
              caption: "",
              body: "",
              blocks3: [],
            },
            {
              id: 414,
              title: "Evening: Golden Hour Energy (3:00 PM - 6:00 PM)",
              subtitle:
                "The studio is filled with the calm buzzing of dedication as the sun sets. Progressive sketches are made, material samples are carefully examined, and careful notes are made.",
              image:
                "https://lh3.googleusercontent.com/d/1XGkqs4oq1LKIeLa6hzQ5kFzR5XfYRYnj=w1000",
              caption: "",
              body: 'Here\'s where the "unsung heroes" come in - the project managers and procurement experts who make sure that every little thing—from finding rare Burmese teak to delicately embroidering a throw pillow—fits into the story that the client is telling.',
              blocks3: [
                {
                  id: 4141,
                  title: "Evenings of Motivation",
                  subtitle:
                    "The exhilaration of realizing a client's vision, the contentment of the day fills in as we take home our inspirations for the day.",
                  image:
                    "https://lh3.googleusercontent.com/d/1GdarEiniGjW6GabDprK2hooT2-e0QlNe=w1000",
                  caption: "",
                  body: "At Spellbound, every project and every day is a new chapter in our ongoing story of creativity and innovation, where a new adventure begins with every sunrise.<br /><br /><b>Stay tuned for more……</b>",
                },
              ],
            },
          ],
        },
      ],
    },
  },
  valuesBlocks: [
    {
      title: "The Human comes First and Spaces Second",
      body: "Spaces are an extension of the user’s beliefs and personality. Our meticulous attention to detail and commitment to intricacy allow the user’s personality to shine through. We prioritize people, designing with their essence in mind to create spaces that truly reflect who they are.",
      image:
        "https://lh3.googleusercontent.com/d/1Y13_GuFKJL5UTrNs_FiOJyHX8IL7_juq=w1000",
    },
    {
      title: "Building an Emotional Narrative",
      body: "Our designs are deeply contextual. We weave warmth and elegance into every space, creating an ambiance that whispers opulence and evokes the right emotions. Each project is a crafted narrative, adding poetry to the design.",
      image:
        "https://lh3.googleusercontent.com/d/1rYqK0saA59PTGhuvVGj_cmx6s2gWJmnx=w1000",
    },
    {
      title: "Indulging with Art",
      body: "For us, interior design transcends mere decoration. We view spaces as blank canvases waiting to be brought to life with stories. We seamlessly integrate art into our designs, evoking a magical sense of indulgence and transforming spaces into living works of art.",
      image:
        "https://lh3.googleusercontent.com/d/1h4KdVOXzm7ukw8NEf-8XOVgUiwwvWWhU=w1000",
    },
    {
      title: "Rigorous Curiosity",
      body: "Curiosity is our design fuel. We dive into the project, analytics, and brief to uncover every space's hidden potential. Our curiosity to know better and design innovation allows us to infuse intentionality into our works.",
      image:
        "https://lh3.googleusercontent.com/d/1OfC7Jd6Gvvgk3tC4PbR6cDmorQwZVeEv=w1000",
    },
  ],
  combinedBlockSlides: [
    {
      url: "/interior/ishavasya",
      title: "Ishavasya",
      image:
        "https://lh3.googleusercontent.com/d/1padtLRouIN6lLQlg1BSUXKQvkqkNr-lZ=w1000",
      showText: true,
    },
    {
      title: "7 Luxury Interior Design Trends that will be leading in 2025",
      image:
        "https://lh3.googleusercontent.com/d/11a6MjgBH4kB2vY5I5OVBHd0iauL_VZCh=w1000",
      url: "/chronicles/interior-design-trends",
      showText: false,
    },
    {
      url: "/interior/the-pavilion",
      title: "The Pavilion",
      image:
        "https://lh3.googleusercontent.com/d/1l3aHsUmz-iT7x27p8y-VTAlWLF7wiFtw=w1000",
      showText: true,
    },
    {
      title: "A Day in the Life of an Interior Designer",
      image:
        "https://lh3.googleusercontent.com/d/1ywV4bYAuxAxiOCPGJjuDl-HN1RQI7L92=w1000",
      url: "/chronicles/life-at-spellbound",
      showText: false,
    },
    {
      url: "/interior/ishavasya",
      title: "The Holy Abode",
      image:
        "https://lh3.googleusercontent.com/d/1nYLppCs29wcI0vyB1bURULXa8kbrAeH0=w1000",
      showText: true,
    },
    {
      title: "Curating the Art of Space: Top 5 Signature Projects",
      image:
        "https://lh3.googleusercontent.com/d/1wQxQ9xibWoH7vG8JwAT1YvrMAat2Cyz8=w1000",
      url: "/chronicles/signature-projects",
      showText: false,
    },
    {
      url: "/interior/beach-house",
      title: "Beach House",
      image:
        "https://lh3.googleusercontent.com/d/1zng5-SFU_VrVkCTlkKspkFjDwBckW3aZ=w1000",
      showText: true,
    },
    {
      title: "Designing for All Senses",
      image:
        "https://lh3.googleusercontent.com/d/1ilmlg1ETgC5xnGy9HwBAi-vpMDayDpzo=w1000",
      url: "/chronicles/sensory-design",
      showText: false,
    },
    {
      url: "/interior/ode-to-timber",
      title: "Godrej N17",
      image:
        "https://lh3.googleusercontent.com/d/1zP0zxxrDwKg6eXfEyFyyc008htLl7hK7=w1000",
      showText: true,
    },
    {
      url: "/interior/essencia",
      title: "Essentia Wellness",
      image:
        "https://lh3.googleusercontent.com/d/1HwwWvxFX6Qc2mpnaI_19zWBTSfiuYYi5=w1000",
      showText: false,
    },
  ],
};
