<template>
  <v-container fluid class="ma-0 pa-0">
    <video v-if="mdAndUp" autoplay muted loop playsinline class="d-block video">
      <source :src="require(`@/assets/${video}`)" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <div v-else class="video-container">
      <video autoplay muted loop playsinline class="d-block mobile-video">
        <source :src="require(`@/assets/${mobileVideo}`)" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </v-container>
</template>

<script>
import { ref, onMounted } from "vue";
import { useDisplay } from "vuetify";
export default {
  name: "BlockOne",
  props: {
    video: {
      type: String,
      required: true,
    },
    mobileVideo: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { mdAndUp } = useDisplay();
    const customHeight = ref(700);
    onMounted(() => {
      customHeight.value =
        Math.max(
          document.documentElement.clientHeight || 0,
          window.innerHeight || 0
        ) - 50;
    });
    return {
      mdAndUp,
      customHeight,
    };
  },
  methods: {
    handleCanPlay() {
      const video = this.$refs.videoPlayer;
      if (video && !video.playing) {
        video.play().catch((error) => {
          console.error("Video autoplay failed:", error);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
video {
  width: 100%;
}
.video-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.mobile-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
